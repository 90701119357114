import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import InsertChartOutlined from "@material-ui/icons/InsertChartOutlined";
import PieChart from "@material-ui/icons/PieChart";
import CardStats from "../../components/Cards/CardStats.js";
import componentStyles from "../../assets/theme/components/header.js";
import { withAppContext } from "../../contextApi/Auth/index.js";
import { useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles(componentStyles);

const Header = ({
  data,
  filteredSales30Days,
  filteredSales7Days,
  filteredSales1Days,
  filteredSales365Days,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <>
      <div className={classes.header}>
        <Container
          maxWidth={false}
          component={Box}
          classes={{ root: classes.containerRoot }}
        >
          <div>
            {data ? null : (
              <Grid container>
                <Grid item xl={3} lg={6} xs={12}>
                  <CardStats
                    subtitle="Todays sales"
                    title={filteredSales1Days.totalAmount + " " + "PKR"}
                    icon={InsertChartOutlined}
                    color="bgWarningLight"
                    footer={
                      <>
                        <Box
                          component="span"
                          fontSize=".875rem"
                          color={theme.palette.success.main}
                          marginRight=".5rem"
                          display="flex"
                          alignItems="center"
                        >
                          {filteredSales1Days.item}
                        </Box>
                        <Box component="span" whiteSpace="nowrap">
                          Number of product
                        </Box>
                      </>
                    }
                  />
                </Grid>

                <Grid item xl={3} lg={6} xs={12}>
                  <CardStats
                    subtitle="7 days sales"
                    title={filteredSales7Days.totalAmount + " " + "PKR"}
                    icon={PieChart}
                    color="bgWarning"
                    footer={
                      <>
                        <Box
                          component="span"
                          fontSize=".875rem"
                          color={theme.palette.success.main}
                          marginRight=".5rem"
                          display="flex"
                          alignItems="center"
                        >
                          {filteredSales7Days.item}
                        </Box>
                        <Box component="span" whiteSpace="nowrap">
                          Number of product
                        </Box>
                      </>
                    }
                  />
                </Grid>
                <Grid item xl={3} lg={6} xs={12}>
                  <CardStats
                    subtitle="30 days sales"
                    title={filteredSales30Days.totalAmount + " " + "PKR"}
                    icon={InsertChartOutlined}
                    color="bgWarningLight"
                    footer={
                      <>
                        <Box
                          component="span"
                          fontSize=".875rem"
                          color={theme.palette.success.main}
                          marginRight=".5rem"
                          display="flex"
                          alignItems="center"
                        >
                          {filteredSales30Days.item}
                        </Box>
                        <Box component="span" whiteSpace="nowrap">
                          Number of product
                        </Box>
                      </>
                    }
                  />
                </Grid>
                <Grid item xl={3} lg={6} xs={12}>
                  <CardStats
                    subtitle="Last year sales"
                    title={filteredSales365Days.totalAmount + " " + "PKR"}
                    icon={InsertChartOutlined}
                    color="bgError"
                    footer={
                      <>
                        <Box
                          component="span"
                          fontSize=".875rem"
                          color={theme.palette.success.main}
                          marginRight=".5rem"
                          display="flex"
                          alignItems="center"
                        >
                          {filteredSales365Days.item}
                        </Box>
                        <Box component="span" whiteSpace="nowrap">
                          Number of product
                        </Box>
                      </>
                    }
                  />
                </Grid>
              </Grid>
            )}
          </div>
        </Container>
      </div>
    </>
  );
};

export default withAppContext(Header);
