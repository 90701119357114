import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Header from "../../components/Headers/Header.js";

import componentStyles from "../../assets/theme/views/admin/tables.js";
import { withAppContext } from "../../contextApi/Auth/index.js";
import { BACKEND_URL } from "../../env.js";
import axios from "axios";
import jsPDF from "jspdf";
import "jspdf-autotable";

const useStyles = makeStyles(componentStyles);

const GuestTable = ({ refresh }) => {
  const classes = useStyles();
  const [AllGuest, setAllGuest] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  // Fetch guest data based on the page
  const fetchGuests = (pageNo = 1) => {
    axios
      .get(`${BACKEND_URL}/GetAllGuest?pageNo=${pageNo}`, {
        headers: { token: localStorage.getItem("Token") },
      })
      .then((res) => {
        setAllGuest(res.data?.data);
        setTotalPages(res.data?.total);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    fetchGuests(page); // Initial fetch
  }, [page, refresh]);

  // Handle page change
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const downloadCSV = () => {
    const columns = ["Name", "Email", "Phone Number"];
    const rows = AllGuest.map((guest) => [
      guest.name || "-",
      guest.email || "-",
      guest.phoneNumber || "-",
    ]);

    const csvHeader = columns.join(",") + "\n";
    const csvRows = rows.map((row) => row.join(",")).join("\n");
    const csvContent = csvHeader + csvRows;

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    const fileName = `user.csv`;

    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute("download", fileName);

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Header />
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <Card classes={{ root: classes.cardRoot }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <CardHeader
              className={classes.cardHeader}
              title="All Guest"
              titleTypographyProps={{
                component: Box,
                marginBottom: "0!important",
                variant: "h3",
              }}
            />
            <Box display="flex" justifyContent="flex-end" padding="1rem">
              <button
                onClick={downloadCSV}
                style={{
                  padding: "7px 14px",
                  backgroundColor: "#1976d2",
                  color: "#fff",
                  border: "none",
                  cursor: "pointer",
                  borderRadius: "5px",
                }}
              >
                Download CSV
              </button>
            </Box>
          </div>

          {AllGuest?.length >= 1 ? (
            <TableContainer>
              <Box
                component={Table}
                alignItems="center"
                marginBottom="0!important"
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      classes={{
                        root: `${classes.tableCellRoot} ${classes.tableCellRootHead}`,
                      }}
                    >
                      Name
                    </TableCell>
                    <TableCell
                      classes={{
                        root: `${classes.tableCellRoot} ${classes.tableCellRootHead}`,
                      }}
                    >
                      Email
                    </TableCell>
                    <TableCell
                      classes={{
                        root: `${classes.tableCellRoot} ${classes.tableCellRootHead}`,
                      }}
                    >
                      Phone Number
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {AllGuest.map((Guest) => (
                    <TableRow key={Guest.id}>
                      <TableCell
                        classes={{
                          root: `${classes.tableCellRoot} ${classes.tableCellRootBodyHead}`,
                        }}
                        component="th"
                        scope="row"
                      >
                        {Guest.name || "-"}
                      </TableCell>
                      <TableCell classes={{ root: classes.tableCellRoot }}>
                        {Guest.email || "-"}
                      </TableCell>
                      <TableCell classes={{ root: classes.tableCellRoot }}>
                        {Guest.phoneNumber || "-"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Box>
            </TableContainer>
          ) : (
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h1>No Guest to Show</h1>
            </div>
          )}
          <Box
            classes={{ root: classes.cardActionsRoot }}
            component={CardActions}
            justifyContent="flex-end"
          >
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              color="primary"
              variant="outlined"
            />
          </Box>
        </Card>
      </Container>
    </>
  );
};

export default withAppContext(GuestTable);
