import React, { useEffect, useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import "sweetalert2/src/sweetalert2.scss";
import Header from "../../components/Headers/Header.js";
import { debounce } from "lodash";
import componentStyles from "../../assets/theme/views/admin/tables.js";
import { withAppContext } from "../../contextApi/Auth/index.js";
import { useHistory } from "react-router-dom";
import { BACKEND_URL } from "../../env.js";
import axios from "axios";
import * as XLSX from "xlsx";

import { io } from "socket.io-client";
const useStyles = makeStyles(componentStyles);
const SaleTable = () => {
  const classes = useStyles();
  const history = useHistory();
  const [AllSale, setAllSale] = useState([]);
  const [data, setData] = useState([]);
  const [sort, setSort] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedButton, setSelectedButton] = useState("");

  const handlePageChange = (event, value) => {
    setSort("");
    setPage(value);
    axios
      .get(
        `${BACKEND_URL}/GetAllSales?page=${value}&limit=25&sort=desc&period=${
          selectedButton ? selectedButton : ""
        }`,
        {
          headers: {
            token: localStorage.getItem("Token"),
          },
        }
      )
      .then((res) => {
        setAllSale(res?.data?.sales);
        setTotalPages(res?.data?.totalPages);
        setData(res?.data?.sales);
      })
      .catch((error) => console.log(error));
  };
  const socket = io(`${BACKEND_URL}`);
  useEffect(() => {
    const newOrderListener = () => {
      GetAllSale();
    };
    socket.on("new_Order", newOrderListener);
    return () => {
      socket.off("new_Order", newOrderListener);
    };
  }, []);

  function GetAllSale(selectPeriod) {
    axios
      .get(
        `${BACKEND_URL}/GetAllSales?limit=20&sort=desc&period=${
          selectPeriod ? selectPeriod : ""
        }`,
        {
          headers: {
            token: localStorage.getItem("Token"),
          },
        }
      )
      .then((res) => {
        setAllSale(res?.data?.sales);
        setTotalPages(res?.data?.totalPages);
        setData(res?.data?.sales);
      })
      .catch((error) => console.log(error));
  }

  useEffect(() => {
    GetAllSale();
  }, []);

  function TrackOrder() {
    AllSale?.map((a) => {
      if (a.TrackingNumber) {
        const config = {
          method: "get",
          url: `https://sonic.pk/api/shipment/track?tracking_number=${Number(
            a.TrackingNumber
          )}&type=${1}`,
          headers: {
            Authorization:
              "U2tQUGpzSnpLV3hhREtSY3VQaHRTdmJXdWNvVHEyNUtGNUVCang2akE1NUxBMWZlRVI3cXVta041SDBV5bd17829af7f2",
          },
        };

        axios(config)
          .then(function (response) {
            a.TraxInfo = response.data.details?.tracking_history?.[0]?.status;
            setAllSale(AllSale);
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    });
  }

  useEffect(() => {
    TrackOrder();
  }, []);

  const handleChange = (event) => {
    setSort(event.target.value);
    if (event.target.value === "confirmed") {
      let arr = [...data];
      let filter = arr?.filter((ev) => ev?.isConfirmed === 1);
      setAllSale(filter);
    } else if (event.target.value === "unconfirmed") {
      let arr = [...data];
      let filter = arr?.filter((ev) => ev?.isConfirmed === 0);
      setAllSale(filter);
    } else if (event.target.value === "cancelled") {
      let arr = [...data];
      let filter = arr?.filter((ev) => ev?.isCanceled === 1);
      setAllSale(filter);
    } else {
      let arr = [...data];
      setAllSale(arr);
    }
  };

  const fetchData = async (textValue, filtered) => {
    if (filtered) {
      if (!textValue.length > 0) {
        return;
      }
      try {
        const response = await fetch(
          `${BACKEND_URL}/SearchSales?search=${textValue}`,
          {
            headers: {
              token: localStorage.getItem("Token"),
            },
          }
        );
        const jsonData = await response.json();
        setData(jsonData?.sales);
        setTotalPages(0);
        setAllSale(jsonData?.sales);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } else {
      GetAllSale(selectedButton);
    }
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchQuery(value);
    if (value.length) {
      debouncedFetchData(value, true);
    } else {
      debouncedFetchData(value, false);
    }
  };

  const debouncedFetchData = debounce(fetchData, 700);

  const getSelectedData = async (value) => {
    setSelectedButton(value);
    if (value?.length > 0) {
      try {
        const api = await fetch(
          `${BACKEND_URL}/GetAllSales?limit=20&sort=desc&period=${value}`,
          {
            headers: {
              token: localStorage.getItem("Token"),
            },
          }
        );
        const res = await api.json();
        console.log(res, "selected");
        if (res.message == "No sales found") {
          setTotalPages(0);
          setAllSale([]);
          setData([]);
          return;
        }
        console.log(res?.totalPages);
        setAllSale(res?.sales);
        setTotalPages(res?.totalPages);
        setData(res?.sales);
      } catch (error) {
        console.log(error.message);
      }
    } else {
      GetAllSale();
    }
  };
  const [loader, setLoader] = useState(false);

  const downloadExcel = async () => {
    setLoader(true);
    try {
      const updatedData = await Promise.all(
        AllSale?.map(async (sale) => {
          const response = await fetch(
            `https://backend.api.sumairatariq.com/GetSaleInfo/${sale.id}`
          );
          const response2 = await fetch(
            `https://backend.api.sumairatariq.com/trackOrder/${sale.id}`
          );
          const saleDetails = await response.json();
          const user = await response2.json();
          const details = saleDetails[0];

          return {
            id: details?.id || sale?.id,
            Date: new Date(
              details?.createdAt || sale?.createdAt
            ).toDateString(),
            Name:user?.guestid?.name,
            Email:user?.guestid?.email,
            PhoneNumber:user?.guestid?.phoneNumber,

            OrderNumber: details?.orderNo || sale?.orderNo || "-",
            ProductTotal: details?.total || sale?.total || 0,
            ShippingAmount:
              details?.shippingCharges || sale?.shippingCharges || 0,
            TotalAmount:
              (details?.total || 0) + (details?.shippingCharges || 0),
            Discount: details?.discount || 0,
            Customer: details?.customerid || sale?.customer?.name || "-",
            Guest: details?.guestid || sale?.guest?.name || "-",
            Delivered: details?.isDelivered ? "Yes" : "No",
            Received: details?.isRecived ? "Yes" : "No",
            Confirmed: details?.isConfirmed ? "Yes" : "No",
            Cancelled: details?.isCanceled ? "Yes" : "No",
            Address: details?.Location
              ? JSON.parse(details?.Location)?.Address
              : "-",
            City: details?.Location ? JSON.parse(details?.Location)?.City : "-",
            Country: details?.Location
              ? JSON.parse(details?.Location)?.Country
              : "-",
            SpecialInstructions: details?.SpecialInstruction || "-",
            Products: details?.saleitems
              ? details.saleitems
                  .map(
                    (item) =>
                      `${item.product?.name} (${item.product?.color}, ${item.product?.size}) x${item.Quantity}`
                  )
                  .join("\n")
              : "-",
          };
        })
      );
  
      const worksheet = XLSX.utils.json_to_sheet(updatedData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "SalesData");
      await XLSX.writeFile(workbook, "SalesData.xlsx");
      setLoader(false);
      console.log("Excel file downloaded successfully.");
    } catch (error) {
      setLoader(false);
      console.error("Error downloading Excel:", error);
    }
  };

  return (
    <>
      <Header />
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <Card classes={{ root: classes.cardRoot }}>
          <CardHeader
            className={classes.cardHeader}
            title="Sale"
            titleTypographyProps={{
              component: Box,
              marginBottom: "0!important",
              variant: "h3",
            }}
          ></CardHeader>

          <div
            style={{
              display: "flex",
              gap: "12px",
              justifyItems: "center",
              padding: "0px 20px",
              paddingTop: "20px",
              marginBottom: "15px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "300px",
                padding: "9px 10px",
                border: "1px solid #ccc",
                borderRadius: "5px",
                fontSize: "14px",
                outline: "none",
                transition: "box-shadow 0.3s ease",
                backgroundColor: "#f7fafc",
              }}
            >
              <input
                placeholder="Search by Order ID"
                value={searchQuery}
                onChange={(ev) => {
                  handleSearchChange(ev);
                }}
                style={{
                  outline: "none",
                  border: "none",
                  width: "100%",
                  backgroundColor: "#f7fafc",
                  color: "gray",
                }}
              />
              <SearchIcon style={{ color: "#aaa" }} />
            </div>
            <div>
              <select
                style={{
                  fontSize: "14px",
                  padding: "9px",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  backgroundColor: "#f7fafc",
                  outline: "none",
                  transition: "box-shadow 0.3s ease",
                  color: "gray",
                }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={sort}
                label="sort"
                onChange={handleChange}
              >
                <option value={"all"}>All</option>
                <option value={"confirmed"}>Confirmed</option>
                <option value={"unconfirmed"}>Unconfirmed</option>
                <option value={"cancelled"}>Cancelled</option>
              </select>
            </div>
          </div>

          <div className={classes.buttonContainer}>
            <div className={classes.ineerbuttonContainer}>
              <Button
                variant="contained"
                color={selectedButton === "" ? "primary" : "secondary"}
                size="small"
                className={`${classes.smallButton} ${
                  selectedButton === "" && classes.blueButton
                }`}
                onClick={() => {
                  getSelectedData("");
                }}
              >
                Get All Data
              </Button>
              <Button
                variant="contained"
                color={selectedButton === "7days" ? "primary" : "secondary"}
                size="small"
                className={`${classes.smallButton} ${
                  selectedButton === "7days" && classes.blueButton
                }`}
                onClick={() => getSelectedData("7days")}
              >
                Last 7 Days
              </Button>

              <Button
                variant="contained"
                color={selectedButton === "20days" ? "primary" : "secondary"}
                size="small"
                className={`${classes.smallButton} ${
                  selectedButton === "20days" && classes.blueButton
                }`}
                onClick={() => getSelectedData("20days")}
              >
                Last 20 Days
              </Button>

              <Button
                variant="contained"
                color={selectedButton === "1month" ? "primary" : "secondary"}
                size="small"
                className={`${classes.smallButton} ${
                  selectedButton === "1month" && classes.blueButton
                }`}
                onClick={() => getSelectedData("1month")}
              >
                Last 1 Month
              </Button>

              <Button
                variant="contained"
                color={
                  selectedButton === "previousyear" ? "primary" : "secondary"
                }
                size="small"
                className={`${classes.smallButton} ${
                  selectedButton === "previousyear" && classes.blueButton
                }`}
                onClick={() => getSelectedData("previousyear")}
              >
                Last 1 year
              </Button>
            </div>

            <Button
              onClick={downloadExcel}
              variant="contained"
              color="primary"
              style={{ marginBottom: "10px" }}
            >
              {loader ? "Downloading..." : "Download Excel"}
            </Button>

            {/* Add similar Button components for other options */}
          </div>
          {AllSale?.length >= 1 ? (
            <TableContainer>
              <Box
                component={Table}
                alignItems="center"
                marginBottom="0!important"
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      classes={{
                        root:
                          classes.tableCellRoot +
                          " " +
                          classes.tableCellRootHead,
                      }}
                    >
                      Date
                    </TableCell>
                    <TableCell
                      classes={{
                        root:
                          classes.tableCellRoot +
                          " " +
                          classes.tableCellRootHead,
                      }}
                    >
                      TrackingNumber
                    </TableCell>
                    <TableCell
                      classes={{
                        root:
                          classes.tableCellRoot +
                          " " +
                          classes.tableCellRootHead,
                      }}
                    >
                      Order Number
                    </TableCell>
                    <TableCell
                      classes={{
                        root:
                          classes.tableCellRoot +
                          " " +
                          classes.tableCellRootHead,
                      }}
                    >
                      Product Total
                    </TableCell>
                    <TableCell
                      classes={{
                        root:
                          classes.tableCellRoot +
                          " " +
                          classes.tableCellRootHead,
                      }}
                    >
                      Shipping Amount
                    </TableCell>
                    <TableCell
                      classes={{
                        root:
                          classes.tableCellRoot +
                          " " +
                          classes.tableCellRootHead,
                      }}
                    >
                      Total Amount
                    </TableCell>
                    {/* <TableCell
											classes={{
												root: classes.tableCellRoot + " " + classes.tableCellRootHead
											}}
										>
											Location
										</TableCell> */}

                    <TableCell
                      classes={{
                        root:
                          classes.tableCellRoot +
                          " " +
                          classes.tableCellRootHead,
                      }}
                    >
                      Customer
                    </TableCell>
                    <TableCell
                      classes={{
                        root:
                          classes.tableCellRoot +
                          " " +
                          classes.tableCellRootHead,
                      }}
                    >
                      Guest
                    </TableCell>
                    <TableCell
                      classes={{
                        root:
                          classes.tableCellRoot +
                          " " +
                          classes.tableCellRootHead,
                      }}
                    >
                      Delivered
                    </TableCell>
                    <TableCell
                      classes={{
                        root:
                          classes.tableCellRoot +
                          " " +
                          classes.tableCellRootHead,
                      }}
                    >
                      Received
                    </TableCell>
                    <TableCell
                      classes={{
                        root:
                          classes.tableCellRoot +
                          " " +
                          classes.tableCellRootHead,
                      }}
                    >
                      Confirmed
                    </TableCell>
                    <TableCell
                      classes={{
                        root:
                          classes.tableCellRoot +
                          " " +
                          classes.tableCellRootHead,
                      }}
                    >
                      Cancelled
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {AllSale?.map((Sale) => {
                    let date = new Date(Sale?.createdAt);
                    return (
                      <TableRow
                        key={Sale?.id}
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          history.push(`DetailSale?id=${Sale?.id}`)
                        }
                      >
                        <TableCell classes={{ root: classes.tableCellRoot }}>
                          {date?.toDateString()} {date?.toLocaleTimeString()}
                        </TableCell>
                        <TableCell
                          classes={{
                            root:
                              classes.tableCellRoot +
                              " " +
                              classes.tableCellRootBodyHead,
                          }}
                          component="th"
                          variant="head"
                          scope="row"
                        >
                          <Box alignItems="center" display="flex">
                            <Box display="flex" alignItems="flex-start">
                              <Box fontSize=".875rem" component="span">
                                {Sale?.TrackingNumber || "-"}
                              </Box>
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell
                          classes={{
                            root:
                              classes.tableCellRoot +
                              " " +
                              classes.tableCellRootBodyHead,
                          }}
                          component="th"
                          variant="head"
                          scope="row"
                        >
                          <Box alignItems="center" display="flex">
                            <Box display="flex" alignItems="flex-start">
                              <Box fontSize=".875rem" component="span">
                                {Sale?.orderNo || "-"}
                              </Box>
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell classes={{ root: classes.tableCellRoot }}>
                          {Sale?.total || 0}
                        </TableCell>
                        <TableCell classes={{ root: classes.tableCellRoot }}>
                          {Sale?.shippingCharges || 0}
                        </TableCell>
                        <TableCell classes={{ root: classes.tableCellRoot }}>
                          {Sale?.total + Sale?.shippingCharges || 0}
                        </TableCell>

                        <TableCell classes={{ root: classes.tableCellRoot }}>
                          {Sale?.customer?.name || "-"}
                        </TableCell>
                        <TableCell
                          classes={{ root: classes.tableCellRoot }}
                          style={
                            {
                              // color: Sale?.isCanceled ? "#fff" : "",
                            }
                          }
                        >
                          {Sale?.guest?.name || "-"}
                        </TableCell>
                        <TableCell
                          classes={{ root: classes.tableCellRoot }}
                          style={
                            {
                              // color: Sale?.isCanceled ? "#fff" : "",
                            }
                          }
                        >
                          {Sale?.isDelivered || "false"}
                        </TableCell>
                        <TableCell
                          classes={{ root: classes.tableCellRoot }}
                          style={
                            {
                              // color: Sale?.isCanceled ? "#fff" : "",
                            }
                          }
                        >
                          {Sale?.isRecived || "false"}
                        </TableCell>
                        <TableCell
                          classes={{ root: classes.tableCellRoot }}
                          style={
                            {
                              // color: Sale?.isCanceled ? "#fff" : "",
                            }
                          }
                        >
                          {Sale?.isConfirmed ? "true" : "false"}
                        </TableCell>
                        <TableCell
                          classes={{ root: classes.tableCellRoot }}
                          style={
                            {
                              // color: Sale?.isCanceled ? "#fff" : "",
                            }
                          }
                        >
                          {Sale?.isCanceled ? "true" : "false"}
                        </TableCell>
                        {/* <TableCell classes={{ root: classes.tableCellRoot }}>
													{Sale?.TraxInfo || "-"}
												</TableCell> */}

                        {/* <TableCell classes={{ root: classes.tableCellRoot }}>
                          <Box display="flex" alignItems="center">
                            <Box
                              component="span"
                              marginRight=".5rem"
                              onClick={() =>
                                Swal.fire({
                                  title: "Are you sure?",
                                  text: "You won't be able to revert this!",
                                  icon: "warning",
                                  showCancelButton: true,
                                  confirmButtonColor: "#3085d6",
                                  cancelButtonColor: "#d33",
                                  confirmButtonText: "Yes, delete it!",
                                  preConfirm: () => {
                                    axios
                                      .post(
                                        `${BACKEND_URL}/DeleteSale/${Sale?.id}`,
                                        {},
                                        {
                                          headers: {
                                            token:
                                              localStorage.getItem("Token"),
                                          },
                                        }
                                      )
                                      .then(() => {
                                        GetAllSale();
                                      })
                                      .catch((res) => console.log(res));
                                  },
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    Swal.fire(
                                      "Deleted!",
                                      "Your Product has been deleted.",
                                      "success"
                                    );
                                  }
                                })
                              }
                            >
                              <Delete
                                style={{
                                  color: "red",
                                  width: "20px",
                                  height: "20px",
                                }}
                              />
                            </Box>
                          </Box>
                        </TableCell> */}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Box>
            </TableContainer>
          ) : (
            <div
              style={{
                width: "100%",
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h1>No Sale to Show</h1>
            </div>
          )}
          <Box
            classes={{ root: classes.cardActionsRoot }}
            component={CardActions}
            justifyContent="flex-end"
          >
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              color="primary"
              variant="outlined"
            />
          </Box>
        </Card>
      </Container>
    </>
  );
};

export default withAppContext(SaleTable);
