import { Box, Button, Container, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Header from "../../components/Headers/Header";
import componentStyles from "../../assets/theme/views/admin/tables.js";
import axios from "axios";
import { BACKEND_URL } from "../../env";
import "./DetailSale.css";
import { toast } from "react-toastify";
import Loader from "../../components/Loader/loader";
import { withAppContext } from "../../contextApi/Auth/index.js";
const useStyles = makeStyles(componentStyles);
const parseFunction = (data) => {
  try {
    let value = JSON.parse(data);
    console.log(value);
    return value;
  } catch (e) {
    console.log(e);
    return {};
  }
};
function DetailSale({ GetAllConfirmSales, setConfirmed, isConfirmed }) {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [date, setDate] = useState();
  const [item, setitem] = useState([]);
  const [ConfirmLoader, setConfirmLoader] = useState(false);
  const [CancelLoader, setCancelLoader] = useState(false);
  var url = new URL(window.location);
  let id = url.searchParams.get("id");

  function Order() {
    axios
      .get(`${BACKEND_URL}/trackOrder/${id}`)
      .then((res) => {
        console.log(res.data, "res.data2");
        setData(res.data);
      })
      .catch((res) => console.log(res, "catch"));
  }
  function ItemsOrder() {
    axios
      .get(`${BACKEND_URL}/GetSaleInfo/${id}`)
      .then((res) => {
        console.log(res.data, "res.data");
        setitem(res.data[0]);
      })
      .catch((res) => console.log(res, "catch"));
  }
  useEffect(() => {
    Order();
    ItemsOrder();
  }, []);
  useEffect(() => {
    console.log(data?.details?.tracking_history.reverse(), "tracking_history");
  }, [data]);
  useEffect(() => {
    let date = new Date(item?.createdAt);
    setDate(date);
  }, [item]);

  let arr = item?.saleitems?.map((ev, i) => ev?.product?.id);
  const cancelFn = () => {
    setCancelLoader(true);
    const config = {
      method: "post",
      url: `${BACKEND_URL}/updateSaleStatus/${id}`,
      data: {
        isConfirmed: 0,
        isCanceled: 1,
        id: arr,
      },
      headers: {
        token: localStorage.getItem("Token"),
      },
    };
    axios(config)
      .then((res) => {
        Order();
        ItemsOrder();
        GetAllConfirmSales();
        setConfirmed(!isConfirmed);
        toast.success("Order Declined", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
        });
        setCancelLoader(false);
      })
      .catch((res) => {
        setCancelLoader(false);
        GetAllConfirmSales();
      });
  };
  const ConfirmFn = () => {
    let arr = [];
    item?.saleitems?.map((ev, i) => {
      arr.push(ev?.product?.id);
    });
    setConfirmLoader(true);
    const config = {
      method: "post",
      url: `${BACKEND_URL}/updateSaleStatus/${id}`,
      data: {
        isConfirmed: 1,
        isDelivered: 1,
        isCanceled: 0,
      },
      headers: {
        token: localStorage.getItem("Token"),
      },
    };
    axios(config)
      .then((res) => {
        GetAllConfirmSales();
        setConfirmed(!isConfirmed);
        Order();
        ItemsOrder();
        toast.success("Order Confirmed", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
        });
        setConfirmLoader(false);
      })
      .catch((res) => {
        GetAllConfirmSales();

        setConfirmLoader(false);
      });
  };

  return (
    <>
      <Header data={true} />
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <div className="Details">
          <div className="Header">
            <h3>
              <strong>Order Details</strong>
            </h3>
            <div className="Order">
              <span>
                Order Date{" "}
                <strong>
                  {date?.toDateString()} {date?.toLocaleTimeString()}
                </strong>
              </span>
              <br />
              <span>
                Order No: <strong>{item?.orderNo}</strong>
              </span>
              <br />
              <span>
                Trax Tracking No:{" "}
                <strong>{data?.details?.tracking_number}</strong>
              </span>
            </div>
            <div className="Btn_parent">
              {item?.isConfirmed ? (
                <h3 style={{ color: "green" }}>
                  <strong>Order Confirmed</strong>
                </h3>
              ) : item?.isCanceled ? (
                <h3 style={{ color: "red" }}>
                  <strong>Order Cancelled</strong>
                </h3>
              ) : item?.isDelivered ? (
                <h3>
                  <strong>Order Delivered</strong>
                </h3>
              ) : (
                <>
                  <Button className="btn Confirm" onClick={() => ConfirmFn()}>
                    {ConfirmLoader ? (
                      <Loader />
                    ) : (
                      <>
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          stroke-width="0"
                          viewBox="0 0 16 16"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M13.485 1.431a1.473 1.473 0 0 1 2.104 2.062l-7.84 9.801a1.473 1.473 0 0 1-2.12.04L.431 8.138a1.473 1.473 0 0 1 2.084-2.083l4.111 4.112 6.82-8.69a.486.486 0 0 1 .04-.045z"></path>
                        </svg>
                        Order Confirm
                      </>
                    )}
                  </Button>
                  <Button className="btn Cancel" onClick={() => cancelFn()}>
                    {CancelLoader ? (
                      <Loader />
                    ) : (
                      <>
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          stroke-width="0"
                          viewBox="0 0 512 512"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M400 145.49L366.51 112 256 222.51 145.49 112 112 145.49 222.51 256 112 366.51 145.49 400 256 289.49 366.51 400 400 366.51 289.49 256 400 145.49z"></path>
                        </svg>
                        Order Cancel
                      </>
                    )}
                  </Button>
                </>
              )}
            </div>
          </div>
          <div className="Body">
            <div className="Inner_Body">
              <div className="Card">
                <h3>
                  <strong>User Information</strong>
                </h3>
                <div className="ImgCards">
                  <div className="Child">
                    {item?.saleitems?.map((e, i) => (
                      <div className="Cards_Inner">
                        <div className="Img_parent">
                          <img
                            src={`${BACKEND_URL}/GetImage/${
                              e?.Images?.filter(
                                (item) => item?.itemColor === e?.product?.color
                              )[0]?.filename
                            }`}
                          />
                        </div>
                        <h4>
                          <strong>{e?.product?.name}</strong>
                          <br />
                          <span>({e?.product?.color})</span>
                        </h4>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="Information">
                <h3>
                  <strong>User Information</strong>
                </h3>
                <div className="Info">
                  <span className="Heading">Name:</span>
                  <span className="InfoBody">
                    <strong>
                      {data?.customerid
                        ? data?.customerid.name
                        : data?.guestid?.name}
                    </strong>
                  </span>
                </div>
                <div className="Info">
                  <span className="Heading">Email:</span>
                  <span className="InfoBody">
                    <strong>
                      {data?.customerid
                        ? data?.customerid.email
                        : data?.guestid?.email}
                    </strong>
                  </span>
                </div>
                <div className="Info">
                  <span className="Heading">phoneNumber:</span>
                  <span className="InfoBody">
                    <strong>
                      {data?.customerid
                        ? data?.customerid.phoneNumber
                        : data?.guestid?.phoneNumber}
                    </strong>
                  </span>
                </div>
                <div className="Info">
                  <span className="Heading">Address:</span>
                  <span className="InfoBody">
                    <strong>
                      {item?.Location
                        ? parseFunction(item?.Location)?.Address || ""
                        : ""}
                    </strong>
                  </span>
                </div>
                <div className="Info">
                  <span className="Heading">City:</span>
                  <span className="InfoBody">
                    <strong>
                      {item?.Location
                        ? parseFunction(item?.Location)?.City || ""
                        : ""}
                    </strong>
                  </span>
                </div>
                <div className="Info">
                  <span className="Heading">State:</span>
                  <span className="InfoBody">
                    <strong>
                      {item?.Location
                        ? parseFunction(item?.Location)?.State || ""
                        : ""}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div className="Parent">
              <div className="Tracking">
                <h3>
                  <strong>Track Order</strong>
                </h3>
                <div className="Tracking_order">
                  {data?.details?.tracking_history.reverse().map((res) => (
                    <div className="Tracking_Card">
                      <h4>
                        <strong>{res?.status}</strong>
                      </h4>
                      <span>{res?.date_time}</span>
                    </div>
                  ))}
                </div>
              </div>
              <div className="Product">
                <h3>
                  <strong>Products</strong>
                </h3>
                <div className="Tracking_order">
                  {item?.saleitems?.map((e, i) => (
                    <div className="Cards_Total color">
                      <h4>
                        <strong>
                          {e?.product?.color}
                          {e?.product?.name}
                        </strong>
                        -{e?.product?.size ? `(${e?.product?.size})` : ""} x(
                        {e?.Quantity})
                      </h4>
                      <span>Rs.{Number(e?.product?.price) * e?.Quantity}</span>
                    </div>
                  ))}
                </div>
              </div>
              <div className="TotalPrice">
                <h3>
                  <strong>Order Summary</strong>
                </h3>
                <div className="Info">
                  <span className="Heading">Product Price:</span>
                  <span className="InfoBody">
                    <strong>Rs.{item?.total}</strong>
                  </span>
                </div>
                <div className="Info">
                  <span className="Heading">Shipping Charges:</span>
                  <span className="InfoBody">
                    <strong>Rs.{item?.shippingCharges}</strong>
                  </span>
                </div>
                <div className="Info InfoTotal">
                  <span className="Heading">Total:</span>
                  <span className="InfoBody">
                    <strong>
                      Rs.{Number(item?.total) + Number(item?.shippingCharges)}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}
export default withAppContext(DetailSale);
