import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withAppContext } from "../../contextApi/Auth/index.js";
import {
  Box,
  Card,
  CardContent,
  FilledInput,
  FormControl,
  FormGroup,
  FormLabel,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";
import componentStyles from "../../assets/theme/views/admin/profile.js";
import { BACKEND_URL } from "../../env.js";

const useStyles = makeStyles(componentStyles);

function CreateCollection({
  createCollection,
  open,
  handleClose,
  UpdateData,
  attachment,
  page,
  setPage,
  loading,
  AllCategory,
  dateFilter,
  setDateFilter,
  setAttachment,
  updateCollection,
  collectionData,
}) {
  const classes = useStyles();

  const [name, setName] = useState("");
  const [file, setFile] = useState("");

  useEffect(() => {
    setName(UpdateData.name);
    setFile(UpdateData.file);
  }, [UpdateData]);

  const [open2, setOpen2] = useState(false);

  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);

  const handleSelect = (filename) => {
    setFile(filename);
    setOpen2(false);
  };

  const clearState = () => {
    setFile("");
    setName("");
  };

  const handleCreateSubmit = () => {
    if (!file || !name) {
      return;
    }

    const Obj = {
      file,
      name,
    };

    if (UpdateData) {
      updateCollection(Obj, handleClose, clearState, UpdateData.id);
    } else {
      if (collectionData?.length < 4) {
        createCollection(Obj, handleClose, clearState);
      } else {
        alert("can't added more than 4");
        handleClose();
        clearState();
      }
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Create Collection
        </DialogTitle>
        <DialogContent>
          <Card classes={{ root: classes.cardRoot }}>
            <Box
              component={CardContent}
              classes={{ root: classes.ptMd4 }}
              paddingTop="0!important"
            >
              <FormGroup>
                <FormLabel>Select Category</FormLabel>

                <FormControl
                  variant="filled"
                  style={{
                    width: "100%",
                    marginBottom: "1rem",
                  }}
                >
                  <Select
                    value={name}
                    style={{ minWidth: 250 }}
                    onChange={(e) => setName(e.target.value)}
                  >
                    <MenuItem value="" disabled>
                      Select Category
                    </MenuItem>
                    {AllCategory.map((category) => (
                      <MenuItem key={category.id} value={category.name}>
                        {category.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </FormGroup>
            </Box>

            <Box component={CardContent} paddingTop="0!important">
              <FormGroup>
                <FormLabel>Image File</FormLabel>
                <Button onClick={handleOpen2} variant="contained">
                  Select Image
                </Button>
                <p>{file}</p>
              </FormGroup>

              <Dialog
                open={open2}
                onClose={handleClose2}
                fullWidth
                maxWidth="lg"
              >
                <DialogContent>
                  <h2 style={{ color: "black", marginBottom: 20 }}>
                    File Manager
                  </h2>
                  <FormGroup>
                    <FormControl
                      variant="filled"
                      style={{
                        width: "30%",
                        marginBottom: "1rem",
                      }}
                    >
                      <Select
                        value={dateFilter}
                        onChange={(e) => {
                          setAttachment([]);
                          setDateFilter(e.target.value);
                        }}
                        displayEmpty
                        style={{ minWidth: 250 }}
                      >
                        <MenuItem value="" disabled>
                          Filter by Date
                        </MenuItem>
                        <MenuItem value="">All</MenuItem>
                        <MenuItem value="today">Today</MenuItem>
                        <MenuItem value="lastWeek">Last Week</MenuItem>
                        <MenuItem value="lastMonth">Last Month</MenuItem>
                        <MenuItem value="lastYear">Last Year</MenuItem>{" "}
                      </Select>
                    </FormControl>
                  </FormGroup>

                  <Box display="grid" gap="1rem" className="grid-container">
                    {attachment?.map(({ filename }) => (
                      <Box
                        key={filename}
                        onClick={() => handleSelect(filename)}
                        style={{
                          cursor: "pointer",
                          width: "100%",
                          padding: 0,
                        }}
                      >
                        <img
                          src={`${BACKEND_URL}/GetImage/${filename}`}
                          style={{
                            width: "100%",
                            height: 200,
                            borderRadius: 5,
                            objectFit: "cover",
                          }}
                        />
                      </Box>
                    ))}
                  </Box>
                  <Box mt={2}>
                    {!loading && (
                      <Button
                        variant="contained"
                        onClick={() => setPage(page + 1)}
                      >
                        Load More
                      </Button>
                    )}
                    {loading && <p>Loading...</p>}
                  </Box>
                </DialogContent>
              </Dialog>
            </Box>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => handleClose()}>
            Cancel
          </Button>
          <Button onClick={() => handleCreateSubmit()}>
            {UpdateData ? "Update" : "Create"} Collection
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withAppContext(CreateCollection);
