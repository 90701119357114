import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import updateIcon from "../../assets/img/brand/Upload icon.png";
import UserHeader from "../../components/Headers/UserHeader.js";
import componentStyles from "../../assets/theme/views/admin/profile.js";
import boxShadows from "../../assets/theme/box-shadow.js";
import { withAppContext } from "../../contextApi/Auth/index.js";
import { useHistory } from "react-router-dom";
import { ImCancelCircle } from "react-icons/im";
import axios from "axios";
import {  Tab, Tabs } from "@material-ui/core";
import { BACKEND_URL } from "../../env.js";
import Loader from "../../components/Loader/loader.js";
import { toast } from "react-toastify";
import { FileUploader } from "react-drag-drop-files";
import "./Drag.css";
import SuspenseImg from "../../components/suspenseImages/suspenseImg.js";
import CropImageModal from "./cropImageModal.js";

const fileTypes = ["JPEG", "jpg", "png", "webp"];

const useStyles = makeStyles(componentStyles);

function Profile({ AllCategory }) {
  const classes = useStyles();

  const [Images, setImages] = useState([]);
  const [ImagesBoll, setImagesBoll] = useState(false);
  const [Images2, setImages2] = useState([]);
  const [ImagesBoll2, setImagesBoll2] = useState(false);
  const [preview, setPreview] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [value, setValue] = React.useState(0);
  const [cropModadal, setCropModal] = useState(false);
  const [cropImage, setCropImage] = useState([]);
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };
  const handleClose = () => {
    setCropModal(false);
    setCropImage([]);
  };
  function toDataURL(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  }

  function GetBannerImages() {
    axios
      .get(`${BACKEND_URL}/GetAllBannerImages`, {
        headers: {
          token: localStorage.getItem("Token"),
        },
      })
      .then((res) => {
        const arr = [];
        // if (res.data.length > 0) {
        console.log("res", res.data);
        const Obj = res.data.sort((a, b) => a?.customIndex - b?.customIndex);
        console.log("ObjObjObjObjObjObjObjObjObjObjObjObj", Obj);
        Obj.map((a, i) => {
          toDataURL(`${BACKEND_URL}/GetImage/${a.filename}`, (url) => {
            arr.push(url);
            if (i === Obj.length - 1) {
              setImages(arr);
              setImagesBoll(!ImagesBoll);
            }
            // arr2.push(a);
          });
        });
        console.log(arr, "arrarrarrarr");
        // setPreview(arr);

        //   setImages(arr2);
      })
      .catch((res) => console.log(res));
  }
  function GetBannerImagesRes() {
    axios
      .get(`${BACKEND_URL}/GetAllResBannerImages`, {
        headers: {
          token: localStorage.getItem("Token"),
        },
      })
      .then((res) => {
        const arr = [];
        console.log("res", res.data);
        // if (res.data.length > 0) {
        // const Obj = res.data;
        const Obj = res.data.sort((a, b) => a?.customIndex - b?.customIndex);
        console.log("ObjObjObjObjObjObjObjObjObjObjObjObjObjObjObjObjObj", Obj);
        Obj.map((a, i) => {
          console.log(a);
          toDataURL(`${BACKEND_URL}/GetImage/${a.filename}`, (url) => {
            arr.push(url);
            if (i === Obj.length - 1) {
              setImages2(arr);
              console.log(arr, "aaaarrrryr");
              setImagesBoll2(!ImagesBoll2);
            }
            // arr2.push(a);
          });
        });
        console.log(arr, "arrarrarrarr");
        // setPreview(arr);
        //   setImages(arr2);
      })
      .catch((res) => console.log(res));
  }
  useEffect(() => {
    GetBannerImages();
    GetBannerImagesRes();
  }, []);
  // useEffect(() => {

  // },[Images])
  useEffect(
    () => setPreview(value === 0 ? Images : Images2),
    [Images, ImagesBoll, value, ImagesBoll2]
  );
  const ImageChange = useRef(null);

  const handleCreateSubmit = (e) => {
    setLoading(true);
    const ImgArr = value === 0 ? Images : Images2;
    const Obj =
      value === 0
        ? {
            Images: ImgArr,
          }
        : {
            Images: ImgArr,
          };
    // console.log(Obj);
    const config = {
      method: "post",
      url: `${BACKEND_URL}/${value === 0 ? "CreateBanner" : "CreateResBanner"}`,
      data: Obj,
      headers: {
        token: localStorage.getItem("Token"),
      },
    };
    axios(config)
      .then((e) => {
        if (value === 0) {
          setImages([]);
          setPreview([]);
          GetBannerImages();
        } else {
          setImages2([]);
          setPreview([]);
          GetBannerImagesRes();
        }
        toast.success("Banner Updated SuccessFully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
        });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(
          err?.response?.data?.message
            ? err?.response?.data?.message
            : "Invalid request",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
          }
        );
      });
  };

  const madeImage = async (file, DataImages, setDataImages) => {
    console.log(Object.values(file), "filefile");

    var reader = new FileReader();
    console.log(reader, "add");
    reader.readAsDataURL(file[0]);
    console.log(reader, "After add");
    reader.onload = function () {
      console.log(reader.result, "result----");
      setCropImage([reader.result]);
      setCropModal(true);
      // if (i === Object.values(file)?.length - 1) {
      // arr.push(reader.result);
      // console.log(Object.values(file)?.length, i, "IMJKHJHJHJH");
      // if (Object.values(file)?.length === i + 1) {
      //   // setDataImages(arr);
      //   // setPreview(arr);
      //   // setImagesBoll2(!ImagesBoll2);
      //   setCropImage(arr);
      //   setCropModal(true);
      // }
      // setImagesBoll(!ImagesBoll);
      // }
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
    // console.log(Object.values(file), "filefile");
    // let arr = [...DataImages];
    // Object.values(file)?.map((a, i) => {
    //   var reader = new FileReader();
    //   reader.readAsDataURL(a);
    //   reader.onload = function () {
    //     // if (i === Object.values(file)?.length - 1) {
    //     arr.push(reader.result);
    //     console.log(Object.values(file)?.length, i, "IMJKHJHJHJH");
    //     if (Object.values(file)?.length === i + 1) {
    //       setDataImages(arr);
    //       setPreview(arr);
    //       setImagesBoll2(!ImagesBoll2);
    //     }
    //     // setImagesBoll(!ImagesBoll);
    //     // }
    //   };
    //   reader.onerror = function (error) {
    //     console.log("Error: ", error);
    //   };
    // });
    // });
    // setPreview(arr);
  };
  const saveImgFn = (img) => {

    if (value === 0) {
      let arr = [...Images];
      arr.push(img);
      setImages(arr);
      setPreview(arr);
    } else if (value === 1) {
      let arr = [...Images2];
      arr.push(img);
      setPreview(arr);
      setImages2(arr);
    }
  };
  return (
    <>
      <UserHeader />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            xl={12}
            component={Box}
            marginBottom="3rem!important"
            classes={{ root: classes.order1 + " " + classes.marginBottomXl0 }}
          >
            <Card classes={{ root: classes.cardRoot }}>
              {/* <Button
								variant="contained"
								size="small"
								classes={{ root: classes.buttonRootDark }}
								onClick={() => handleCreateSubmit()}
							>
								Update Banner
							</Button> */}
              <Tabs
                className="colorImageTab"
                value={value}
                indicatorColor="primary"
                textColor="primary"
                // onChange={handleChangeTab}
              >
                {/* {colorSelected.map((a, i) => ( */}
                <Tab
                  key={0}
                  className="buttonTab"
                  label={"Destop view"}
                  onClick={() => setValue(0)}
                />
                <Tab
                  key={1}
                  className="buttonTab"
                  label={"Mobile view"}
                  onClick={() => setValue(1)}
                />
                {/* ))} */}
              </Tabs>
              <Box component={Grid} container style={{ padding: "10px  20px" }}>
                {/* <Slider {...settings}>
                  {preview?.map((a, i) => (
                    <div style={{ position: "relative" }}>
                      <img src={a} width="100%" />
                      <ImCancelCircle
                        style={{
                          position: "absolute",
                          top: "0px",
                          fontSize: "20px",
                          color: "black",
                          cursor: "pointer",
                          left: "-5px",
                        }}
                        onClick={() => {
                          let arr = [...preview];
                          arr.splice(i, 1);
                          setPreview([...arr]);
                        }}
                      />
                    </div>
                  ))}
                </Slider> */}
                {/* <Grid
                  item
                  xs={12}
                  sm={4}
                  md={12}
                  justifycontent="center"
                  alignitem="center"
                  aligncontent="center"
                  display="flex"
                >
                  <Box
                    position="relative"
                    onClick={() => {
                      if (ImageChange.current) ImageChange.current.click();
                    }}
                  >
                    <Box
                      component="img"
                      src={require("../../assets/img/uploadicon30.png").default}
                      alt="..."
                      maxWidth="250px"
                      height="180px"
                      width="180px"
                      margin="10px 5px"
                      objectFit="contain"
                      style={{
                        backgroundColor: "transparent",
                      }}
                      boxShadow={boxShadows.boxShadow + "!important"}
                    />
                    <p>Upload or drag a file right here</p>
                    <FileUploader
                      classes="imageUplaodlabel2"
                      multiple={true}
                      handleChange={(e) => {
                        console.log(e, "EEEEEEEEEEEEEEEEEEEEEERRRERRERE");
                        // e?.map((a) => {
                        madeImage(
                          e,
                          value === 0 ? Images : Images2,
                          value === 0 ? setImages : setImages2
                        );
                        // });
                      }}
                      name="file"
                      types={fileTypes}
                    />
                  </Box>
                </Grid> */}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  justifycontent="center"
                  alignitem="center"
                  aligncontent="center"
                  display="flex"
                  style={{ marginBottom: 10 }}
                >
                  <Box
                    position="relative"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      if (ImageChange.current) ImageChange.current.click();
                    }}
                  >
                    <label style={{ cursor: "pointer" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          padding: "32px 17px",
                          border: "1px dotted #5e72e4",
                          borderRadius: "10px",
                        }}
                      >
                        <img src={updateIcon} />
                        <h4 style={{ marginBottom: 11, marginTop: 11 }}>
                          Drag & drop files or <span>Browse</span>
                        </h4>
                        <p>Supported formates: JPEG, PNG,</p>
                      </div>
                      <FileUploader
                        classes="imageUplaodlabel2 FileUploader-banner"
                        multiple={true}
                        handleChange={(e) => {
                          console.log(e, "EEEEEEEEEEEEEEEEEEEEEERRRERRERE");
                          // e?.map((a) => {
                          madeImage(
                            e,
                            value === 0 ? Images : Images2,
                            value === 0 ? setImages : setImages2
                          );
                          // });
                        }}
                        name="file"
                        types={fileTypes}
                      />
                    </label>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={12}
                  // justifycontent="center"
                  // alignitem="center"
                  // aligncontent="center"
                  style={{ display: "flex", overflow: "auto" }}
                  display="flex"
                >
                  {preview?.map((a, i) => {
                    return value === 0 ? (
                      <Box position="relative" key={i}>
                        <div
                          style={{
                            minWidth: 200,
                            height: 200,
                            margin: "10px 5px",
                          }}
                        >
                          <SuspenseImg
                            component="img"
                            src={a}
                            alt="..."
                            style={{
                              maxWidth: "250px",
                              height: "180px",
                              width: "180px",
                              margin: "10px 5px",
                            }}
                            boxShadow={boxShadows.boxShadow + "!important"}
                          />
                        </div>
                        <ImCancelCircle
                          style={{
                            position: "absolute",
                            top: "0px",
                            fontSize: "20px",
                            color: "black",
                            cursor: "pointer",
                            left: "-5px",
                          }}
                          onClick={() => {
                            let arr = [...Images];
                            arr.splice(i, 1);
                            setImages([...arr]);
                          }}
                        />
                      </Box>
                    ) : (
                      <Box position="relative">
                        <div
                          style={{
                            minWidth: 200,
                            height: 200,
                            margin: "10px 5px",
                          }}
                        >
                          <SuspenseImg
                            component="img"
                            src={a}
                            alt="..."
                            style={{
                              maxWidth: "250px",
                              height: "180px",
                              width: "180px",
                              margin: "10px 5px",
                            }}
                            boxShadow={boxShadows.boxShadow + "!important"}
                          />
                        </div>
                        <ImCancelCircle
                          style={{
                            position: "absolute",
                            top: "0px",
                            fontSize: "20px",
                            color: "black",
                            cursor: "pointer",
                            left: "-5px",
                          }}
                          onClick={() => {
                            let arr = [...Images2];
                            arr.splice(i, 1);
                            setImages2([...arr]);
                            setImagesBoll2(!ImagesBoll2);
                            setPreview([...arr]);
                          }}
                        />
                      </Box>
                    );
                  })}
                </Grid>
              </Box>
              <Button
                style={{ padding: "10px  20px", margin: "10px  20px" }}
                variant="contained"
                size="small"
                classes={{ root: classes.buttonRootDark }}
                onClick={() => handleCreateSubmit()}
                disabled={
                  Loading || (value === 0 && Images?.length === 0)
                    ? true
                    : value === 1 && Images2?.length === 0
                    ? true
                    : false
                }
              >
                {Loading ? (
                  <Loader cumtomStyle={{ position: "unset" }} />
                ) : (
                  "Update Banner"
                )}
              </Button>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <CropImageModal
        show={cropModadal}
        onClose={handleClose}
        setCropImage={setCropImage}
        cropImage={cropImage}
        saveImgFn={saveImgFn}
        aspect={value === 0 ? 16 / 9 : 9 / 16}
      />
    </>
  );
}

export default withAppContext(Profile);
