import React, { useRef } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Box, Card, CardContent, makeStyles } from "@material-ui/core";
import Loader from "../../components/Loader/loader";
import { useState } from "react";
import { Cropper } from "react-cropper";
import "cropperjs/dist/cropper.css";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function CropImageModal({
  show,
  onClose,
  cropImage,
  aspect,
  saveImgFn,
}) {
  const classes = useStyles();
  const cropperRef = useRef(null);
  const [croppedImg, setCroppedImg] = useState("");
  const [Loading, setLoading] = useState(false);
  const onCrop = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    setCroppedImg(cropper.getCroppedCanvas().toDataURL());
  };
  return (
    <Dialog
      open={show}
      onClose={() => onClose()}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
        Crop Image
      </DialogTitle>
      <DialogContent>
        <Card classes={{ root: classes.cardRoot }}>
          <Box
            component={CardContent}
            classes={{ root: classes.ptMd4 }}
            paddingTop="0!important"
          >
            <Cropper
              src={cropImage[0]}
              // style={{ height: 500, width: "732px" }}
              initialAspectRatio={aspect}
              guides={true}
              crop={onCrop}
              ref={cropperRef}
              // viewMode={1}
              // minCropBoxHeight={10}
              // minCropBoxWidth={10}
              // background={false}
              // responsive={true}
              // autoCropArea=sq{1}
              aspectRatio={aspect}
              // checkOrientation={false}
            />
            {/* <img src={croppedImg} /> */}
          </Box>
        </Card>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={() => onClose()}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            setLoading(true);
            saveImgFn(croppedImg);
            onClose();
            setCroppedImg("");
            setLoading(false);
          }}
        >
          {Loading ? <Loader cumtomStyle={{ position: "unset" }} /> : "Confirm"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
