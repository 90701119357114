import {
  useLocation,
  Route,
  Switch,
  Redirect,
  useHistory,
} from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import AuthHeader from "../components/Headers/AuthHeader.js";
import AuthFooter from "../components/Footers/AuthFooter.js";
import routes from "../routes.js";
import componentStyles from "../assets/theme/layouts/auth.js";
import { useEffect, useRef } from "react";

const useStyles = makeStyles(componentStyles);

const Auth = () => {
  const history = useHistory();
  const classes = useStyles();
  const mainContent = useRef(null);
  const location = useLocation();

  useEffect(() => {
    document.body.classList.add(classes.bgDefault);
    return () => {
      document.body.classList.remove(classes.bgDefault);
    };
  });
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);
  useEffect(() => {
    let token = localStorage.getItem("Token");
    let url = window.location.pathname;
    if (token) {
      history.push("/admin/index");
    }
  }, [window.location.pathname, localStorage.getItem("Token")]);
  const getSwitch = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <div className="main-content" ref={mainContent}>
        <AuthHeader />
        <Container
          component={Box}
          maxWidth="xl"
          marginTop="-8rem"
          paddingBottom="3rem"
          position="relative"
          zIndex="101"
        >
          <Box component={Grid} container justifyContent="center">
            <Switch>
              {getSwitch(routes)}
              <Redirect from="/" to="/auth/login" />
            </Switch>
          </Box>
        </Container>
      </div>
      <AuthFooter />
    </>
  );
};

export default Auth;
