import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withAppContext } from "../../contextApi/Auth";
import {
  Box,
  Card,
  CardContent,
  FilledInput,
  FormControl,
  FormGroup,
  FormLabel,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";
import componentStyles from "../../assets/theme/views/admin/profile.js";

const useStyles = makeStyles(componentStyles);

function CreateShipment({
  createShipment,
  open,
  handleClose,
  updateShipment,
  setUpdateData,
  UpdateData,
  shipmentData,
}) {
  const classes = useStyles();

  const [amount, setAmount] = useState("");
  const [additional, setAdditional] = useState("");
  const [city, setCity] = useState("");

  const clearState = () => {
    setAmount("");
    setAdditional("");
    setCity("");
    setUpdateData("");
  };
  const capitalizeText = (text) => {
    const firstLetter = text;
    const remainLetters = text.slice(1).toLowerCase();
    const first = firstLetter.charAt(0).toUpperCase();
    return first + remainLetters;
  };

  const handleCreateSubmit = (e) => {
    if (!amount || !additional || !city) {
      return;
    }
    const Obj = {
      amount: Number(amount || 0),
      additional: Number(additional || 0),
      city,
    };
    const existKarachi = shipmentData.find((a) => a.city === "karachi");
    const existOtherCity = shipmentData.find((a) => a.city === "otherCity");

    if (UpdateData) {
      if (
        (existKarachi &&
          Obj.city == "karachi" &&
          existKarachi.id !== UpdateData.id) ||
        (existOtherCity &&
          Obj.city == "otherCity" &&
          existOtherCity.id !== UpdateData.id)
      ) {
        alert("You cannot update to a city that already exists.");
        return;
      }
      updateShipment(Obj, handleClose, clearState, UpdateData.id);
      return;
    } else {
      if (existKarachi && Obj.city == "karachi") {
        alert("A shipment for Karachi already exists. You can't add it again.");
        return;
      }

      if (existKarachi && existOtherCity) {
        alert("You can't add both Karachi and otherCity shipments.");
        return;
      }

      createShipment(Obj, handleClose, clearState);
    }
  };

  useEffect(() => {
    setAmount(UpdateData.amount);
    setCity(UpdateData.city);
    setAdditional(UpdateData.additional);
  }, [UpdateData]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Create Color
        </DialogTitle>
        <DialogContent>
          <Card classes={{ root: classes.cardRoot }}>
            <Box
              component={CardContent}
              classes={{ root: classes.ptMd2 }}
              paddingTop="0!important"
            >
              <FormGroup>
                <FormLabel>Amount</FormLabel>
                <FormControl
                  variant="filled"
                  component={Box}
                  width="100%"
                  marginBottom="1rem!important"
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    autoComplete="off"
                    type="text"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </FormControl>
              </FormGroup>
            </Box>
            <Box
              component={CardContent}
              classes={{ root: classes.ptMd2 }}
              paddingTop="0!important"
            >
              <FormGroup>
                <FormLabel>City</FormLabel>
                <FormControl
                  variant="filled"
                  component={Box}
                  width="100%"
                  marginBottom="1rem!important"
                >
                  <Select
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  >
                    <MenuItem value="">Select a city</MenuItem>
                    <MenuItem value="karachi">Karachi</MenuItem>
                    <MenuItem value="otherCity">Other City</MenuItem>
                  </Select>
                </FormControl>
              </FormGroup>
            </Box>
            <Box
              component={CardContent}
              classes={{ root: classes.ptMd2 }}
              paddingTop="0!important"
            >
              <FormGroup>
                <FormLabel>Additional Amount</FormLabel>
                <FormControl
                  variant="filled"
                  component={Box}
                  width="100%"
                  marginBottom="1rem!important"
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    autoComplete="off"
                    type="text"
                    value={additional}
                    onChange={(e) => setAdditional(e.target.value)}
                  />
                </FormControl>
              </FormGroup>
            </Box>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => handleClose()}>
            Cancel
          </Button>
          <Button onClick={() => handleCreateSubmit()}>
            {UpdateData ? "Update" : "Create"} Shipment
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withAppContext(CreateShipment);
