import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";
import {
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tab,
  Paper,
  Button,
} from "@material-ui/core";
import Loader from "../../../components/Loader/loader";
import { FileUploader } from "react-drag-drop-files";
import SuspenseImg from "../../../components/suspenseImages/suspenseImg";
import { ImCancelCircle } from "react-icons/im";
import "../TagStyle.css";
// import Dropzone from "react-dropzone";
// import sharp from 'sharp';

function StepThree({
  classes,
  productId,
  colorSelected,
  value,
  handleChangeTab,
  setSelectedColor,
  setTabColor,
  imageUpload,
  setImageUpload,
  madeImage,
  SelectedColor,
  fileTypes,
  loading,
  addProductImages,
  TabColor,
}) {
  const [webpImage, setWebpImage] = useState(null);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  // const handleImageUpload = async (acceptedFiles) => {
  //     const imageFile = acceptedFiles[0];

  //     // Use sharp to convert the image to WebP format
  //     const webpBuffer = await sharp(imageFile.path)
  //         .webp()
  //         .toBuffer();

  //     // Create a Blob from the WebP buffer
  //     const webpBlob = new Blob([webpBuffer], { type: 'image/webp' });

  //     // Create a URL for the Blob
  //     const webpUrl = URL.createObjectURL(webpBlob);

  //     // Set the WebP image URL in the state
  //     setWebpImage(webpUrl);
  // };

  return (
    <Card
      classes={{
        root: classes.cardRoot + " " + classes.cardRootSecondary,
      }}
    >
      <CardHeader
        subheader={
          <Grid
            container
            component={Box}
            alignitem="center"
            justifycontent="space-between"
          >
            <Grid item xs="auto">
              <Box
                component={Typography}
                variant="h3"
                marginBottom="0!important"
              >
                Enter Images
              </Box>
            </Grid>
          </Grid>
        }
        // action={
        //     <Button
        //         variant="contained"
        //         size="small"
        //         classes={{ root: classes.buttonRootDark }}
        //         onClick={() => handleCreateSubmit()}
        //     >
        //         {loading ? <Loader /> : productId ? "Update" : "Create"}
        //     </Button>
        // }
        classes={{ root: classes.cardHeaderRoot }}
      ></CardHeader>
      <CardContent>
        <Grid
          item
          xs={12}
          style={{
            padding: "0px",
          }}
        >
          <Tabs
            className="colorImageTab"
            value={value}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChangeTab}
          >
            {colorSelected.map((a, i) => {
              return (
                <Tab
                  key={a}
                  className="buttonTab"
                  label={a}
                  onClick={() => {
                    console.log(a, TabColor, "a, TabColor");
                    setSelectedColor(a);
                    setTabColor(a, TabColor);
                  }}
                />
              );
            })}
          </Tabs>
          {colorSelected.map((a, i) => (
            <TabPanel key={a} value={value} index={i}>
              <FormGroup>
                <FormLabel>
                  Color{" "}
                  <span style={{ color: "red" }}>
                    Upload atleast 2 image/video for each picture
                  </span>
                </FormLabel>
                <div className="ImagesDiv">
                  {imageUpload?.map((b, i) => {
                    return b?.color === a ? (
                      <>
                        <label className="imageUplaodlabel">
                          <SuspenseImg src={b.image} type={b.type} />
                          <div
                            className="cancelImage"
                            onClick={() => {
                              let arr = [...imageUpload];
                              arr.splice(imageUpload.indexOf(b), 1);
                              setImageUpload(arr);
                            }}
                          >
                            <ImCancelCircle className="cancelImagIcon" />
                          </div>
                        </label>
                      </>
                    ) : null;
                  })}
                  <FileUploader
                    className="imageUplaodlabel LabelImage"
                    multiple={true}
                    handleChange={(e) => {
                      madeImage(e, SelectedColor, i);
                    }}
                    name="file"
                    types={fileTypes}
                  />
                </div>
              </FormGroup>
            </TabPanel>
          ))}
        </Grid>
        <Button
          style={{
            width: "100%",
            // margin: "0 1.5rem",
            padding: "10px 20px",
            marginBottom: "20px",
          }}
          variant="contained"
          size="small"
          className="addVariety"
          classes={{ root: classes.buttonRootDark }}
          onClick={() => addProductImages()}
        >
          {loading ? (
            <Loader />
          ) : productId ? (
            "Update Product Images & Next"
          ) : (
            "Add Product Images & Next"
          )}
        </Button>
      </CardContent>
    </Card>
  );
}
export default StepThree;
