import axios from "axios";
import React, { createContext, useState, useEffect } from "react";
import { BACKEND_URL } from "../../env";
import { toast } from "react-toastify";
import Swal from "sweetalert2/dist/sweetalert2.js";

export const AppContext = createContext();
export const withAppContext = (Component) => (props) =>
  (
    <AppContext.Consumer>
      {(value) => <Component {...value} {...props} />}
    </AppContext.Consumer>
  );

const AppProvider = ({ children }) => {
  const token = localStorage.getItem("Token");
  const [AllCategory, setAllCategory] = useState([]);
  const [AllMultipleProducts, setAllMultipleProducts] = useState([]);
  const [GetToken, setGetToken] = useState(null);
  const [refresh, setRefresh] = useState(false);
  function GetLogin(Obj, setLoading, history) {
    const config = {
      method: "post",
      url: `${BACKEND_URL}/LoginUser`,
      data: Obj,
    };
    axios(config)
      .then((res) => {
        // console.log(res, "res");
        if (res.data.token) {
          setGetToken(res.data.token);
          console.log(res.data, "res");
          localStorage.setItem("Token", res.data.token);
          history.push("/admin/index");
          setLoading(false);
          toast.success("Login Successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
          });
        } else {
          // 	// Swal.fire({
          // 	// 	title: "Are you sure?",
          // 	// 	text: "Your Credintials Are not Correct!",
          // 	// 	icon: "warning",
          // 	// 	showCancelButton: true,
          // 	// 	confirmButtonColor: "#3085d6",
          // 	// 	cancelButtonColor: "#d33",
          // 	// 	confirmButtonText: "Retry"
          // 	// });
          toast.error(
            res?.data?.message ? res?.data?.message : "Invalid error",
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
            }
          );
          setLoading(false);
        }
      })
      .catch((res) => {
        console.log(res, "res");
        Swal.fire({
          // title: "Are you sure?",
          text: "Your Credintials Are not Correct!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Retry",
        });
        setLoading(false);
      });
  }
  function GetAllCategories() {
    axios
      .get(`${BACKEND_URL}/GetAllCategories`, {
        headers: {
          token: localStorage.getItem("Token"),
        },
      })
      .then((res) => {
        setAllCategory(res.data);
      })
      .catch((res) => console.log(res));
  }
  function GetAllMultipleProducts() {
    axios
      .get(`${BACKEND_URL}/filterProduct`, {
        headers: {
          token: localStorage.getItem("Token"),
        },
      })
      .then((res) => {
        setAllMultipleProducts(res?.data?.data || []);
      })
      .catch((res) => console.log(res));
  }

  useEffect(() => {
    GetAllCategories();
  }, [!refresh]);

  const [AllProducts, setAllProducts] = useState([]);
  const [AllRatings, setAllRatings] = useState([]);

  const [filteredSales365Days, SetfilteredSales7Days] = useState({
    totalAmount: 0,
    item: 0,
  });
  const [filteredSales7Days, SetfilteredSales20Days] = useState({
    totalAmount: 0,
    item: 0,
  });
  const [filteredSales30Days, SetfilteredSales30Days] = useState({
    totalAmount: 0,
    item: 0,
  });
  const [filteredSales1Days, SetfilteredSales1Days] = useState({
    totalAmount: 0,
    item: 0,
  });
  function GetAllProducts() {
    axios
      .get(`${BACKEND_URL}/GetAllProducts`, {
        headers: {
          token: localStorage.getItem("Token"),
        },
      })
      .then((res) => {
        setAllProducts(res.data);
      })
      .catch((res) => console.log(res));
  }
  function GetAllConfirmSales() {
    axios
      .get(`${BACKEND_URL}/GetAllUnConfirmedSales?isConfirmed=${true}`, {
        headers: {
          token: localStorage.getItem("Token"),
        },
      })
      .then((res) => {
        SetfilteredSales1Days(calculateTodaySales(res?.data));
        SetfilteredSales7Days(calculateTotalSalesByDays(res?.data, 365));
        SetfilteredSales20Days(calculateTotalSalesByDays(res?.data, 7));
        SetfilteredSales30Days(calculateTotalSalesForLastMonth(res?.data));
      })
      .catch((res) => console.log(res));
  }

  function calculateTotalSalesForLastMonth(sales) {
    const today = new Date();
    const pastDate = new Date(today);
    pastDate.setMonth(today.getMonth() - 1); // Last month

    // Filter sales within the date range
    const filteredSales = sales.filter((sale) => {
      const saleDate = new Date(sale.createdAt);
      return saleDate >= pastDate && saleDate <= today;
    });

    // Use reduce to calculate the total of all products' total price
    const totalAmount = filteredSales.reduce((acc, sale) => {
      return acc + sale.total;
    }, 0);

    return { totalAmount, item: filteredSales?.length };
  }

  function calculateTodaySales(sales) {
    const today = new Date();
    const startOfDay = new Date(today.setHours(0, 0, 0, 0));
    const endOfDay = new Date(today.setHours(23, 59, 59, 999));

    const todaySales = sales.filter((sale) => {
      const saleDate = new Date(sale.createdAt);
      return saleDate >= startOfDay && saleDate <= endOfDay;
    });

    const totalAmount = todaySales.reduce((acc, sale) => {
      return acc + sale.total;
    }, 0);

    return { totalAmount, item: todaySales?.length };
  }

  function calculateTotalSalesByDays(sales, days) {
    const today = new Date();
    const pastDate = new Date(today);
    pastDate.setDate(today.getDate() - days);

    const filteredSales = sales.filter((sale) => {
      const saleDate = new Date(sale.createdAt);
      return saleDate >= pastDate && saleDate <= today;
    });

    const totalAmount = filteredSales.reduce((acc, sale) => {
      return acc + sale.total;
    }, 0);

    return { totalAmount, item: filteredSales?.length };
  }
  useEffect(() => {
    GetAllConfirmSales();
  }, []);

  function GetAllRatings() {
    axios
      .get(`${BACKEND_URL}/GetAllRatingWithProductDetails`, {
        headers: {
          token: localStorage.getItem("Token"),
        },
      })
      .then((res) => {
        setAllRatings(res.data);
      })
      .catch((res) => console.log(res));
  }

  const [colorData, setColorData] = useState([]);
  const [addColorLoading, setAddColorLoading] = useState([]);
  const [colorLoading, setColorLoading] = useState([]);
  function createColor(Obj, handleClose, clearState) {
    setAddColorLoading(true);
    const config = {
      method: "post",
      url: `${BACKEND_URL}/CreateColors`,
      data: Obj,
      headers: {
        token: localStorage.getItem("Token"),
      },
    };
    axios(config)
      .then((res) => {
        handleClose();
        getColor();
        clearState();
        setAddColorLoading(false);
      })
      .catch((res) => {
        setAddColorLoading(false);
      });
  }
  function getColor() {
    setColorLoading(true);
    axios
      .get(`${BACKEND_URL}/GetAllColors`, {
        headers: {
          token: localStorage.getItem("Token"),
        },
      })
      .then((data) => {
        setColorData(data.data.data);
        setColorLoading(false);
      })
      .catch(() => {
        setColorLoading(false);
      });
  }
  function updateColor(Obj, handleClose, clearState, id) {
    setAddColorLoading(true);
    const config = {
      method: "post",
      url: `${BACKEND_URL}/UpdateColor/${id}`,
      data: Obj,
      headers: {
        token: localStorage.getItem("Token"),
      },
    };
    axios(config)
      .then((res) => {
        handleClose();
        getColor();
        clearState();
        setAddColorLoading(false);
      })
      .catch((res) => {
        setAddColorLoading(false);
      });
  }
  function deleteColor(id) {
    setAddColorLoading(true);
    const config = {
      method: "post",
      url: `${BACKEND_URL}/DeleteColor/${id}`,
      headers: {
        token: localStorage.getItem("Token"),
      },
    };
    axios(config)
      .then((res) => {
        getColor();
      })
      .catch((res) => {});
  }

  //Tags
  const [addTagLoading, setAddTagLoading] = useState([]);
  const [TagsData, setTagsData] = useState([]);
  const [addTagsLoading, setAddTagsLoading] = useState([]);
  function getTags() {
    setAddTagsLoading(true);
    axios
      .get(`${BACKEND_URL}/getAllTags`, {
        headers: {
          token: localStorage.getItem("Token"),
        },
      })
      .then((data) => {
        setTagsData(data?.data?.tags);
        setAddTagsLoading(false);
      })
      .catch(() => {
        setAddTagsLoading(false);
      });
  }
  // function updateTags(Obj, handleClose, clearState, id) {
  //   setAddTagLoading(true);
  //   const config = {
  //     method: "post",
  //     url: `${BACKEND_URL}/UpdateColor/${id}`,
  //     data: Obj,
  //     headers: {
  //       token: localStorage.getItem("Token"),
  //     },
  //   };
  //   axios(config)
  //     .then((res) => {
  //       handleClose();
  //       getColor();
  //       clearState();
  //       setAddTagLoading(false);
  //     })
  //     .catch((res) => {
  //       setAddTagLoading(false);
  //     });
  // }

  // function deleteTags(id) {
  //   setAddTagLoading(true);
  //   const config = {
  //     method: "post",
  //     url: `${BACKEND_URL}/DeleteColor/${id}`,
  //     headers: {
  //       token: localStorage.getItem("Token"),
  //     },
  //   };
  //   axios(config)
  //     .then((res) => {
  //       getColor();
  //     })
  //     .catch((res) => {});
  // }

  // Size
  const [sizeData, setSizeData] = useState([]);
  const [shipmentData, setShipmentData] = useState([]);
  const [addSizeLoading, setAddSizeLoading] = useState([]);
  const [sizeLoading, setSizeLoading] = useState([]);

  const [collectionData, setCollectionData] = useState([]);
  const [attachment, setAttachment] = useState([]);

  function getAllCollectionImage() {
    axios
      .get(`${BACKEND_URL}/getAllCollectionImage`, {
        headers: {
          token: localStorage.getItem("Token"),
        },
      })
      .then((data) => {
        setCollectionData(data.data.data);
      })
      .catch(() => {});
  }

  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [dateFilter, setDateFilter] = useState("");

  async function getAllAttachment() {
    setLoading(true);
    try {
      const response = await axios.get(
        `${BACKEND_URL}/getAllAttachment?page=${page}&limit=30&dateFilter=${dateFilter}`
      );
      const data = response.data.data;

      setAttachment((prev) => [...prev, ...data]);
    } catch (error) {
      console.error("Error fetching attachments:", error);
    } finally {
      setLoading(false);
    }
  }

  function createSize(Obj, handleClose, clearState) {
    setAddSizeLoading(true);
    const config = {
      method: "post",
      url: `${BACKEND_URL}/CreateSizes`,
      data: Obj,
      headers: {
        token: localStorage.getItem("Token"),
      },
    };
    axios(config)
      .then((res) => {
        handleClose();
        getSize();
        clearState();
        setAddSizeLoading(false);
      })
      .catch((res) => {
        setAddSizeLoading(false);
      });
  }

  function createShipment(Obj, handleClose, clearState) {
    setAddSizeLoading(true);
    const config = {
      method: "post",
      url: `${BACKEND_URL}/CreateShipment`,
      data: Obj,
      headers: {
        token: localStorage.getItem("Token"),
      },
    };
    axios(config)
      .then((res) => {
        handleClose();
        getAllShipment();
        clearState();
        setAddSizeLoading(false);
      })
      .catch((res) => {
        setAddSizeLoading(false);
      });
  }

  function createCollection(Obj, handleClose, clearState) {
    const config = {
      method: "post",
      url: `${BACKEND_URL}/CreateCollectionImage`,
      data: Obj,
      headers: {
        token: localStorage.getItem("Token"),
      },
    };
    axios(config)
      .then((res) => {
        getAllCollectionImage();
        handleClose();
        clearState();
      })
      .catch((res) => {});
  }

  function getSize() {
    setSizeLoading(true);
    axios
      .get(`${BACKEND_URL}/GetAllSizes/`, {
        headers: {
          token: localStorage.getItem("Token"),
        },
      })
      .then((data) => {
        setSizeData(data.data.data);
        setSizeLoading(false);
      })
      .catch(() => {
        setSizeLoading(false);
      });
  }
  function getAllShipment() {
    axios
      .get(`${BACKEND_URL}/getAllShipment`, {
        headers: {
          token: localStorage.getItem("Token"),
        },
      })
      .then((data) => {
        setShipmentData(data.data.data);
      })
      .catch(() => {});
  }

  function updateSize(Obj, handleClose, clearState, id) {
    setAddSizeLoading(true);
    const config = {
      method: "post",
      url: `${BACKEND_URL}/UpdateSize/${id}`,
      data: Obj,
      headers: {
        token: localStorage.getItem("Token"),
      },
    };
    axios(config)
      .then((res) => {
        handleClose();
        getSize();
        clearState();
        setAddSizeLoading(false);
      })
      .catch((res) => {
        setAddSizeLoading(false);
      });
  }

  function updateCollection(Obj, handleClose, clearState, id) {
    const config = {
      method: "patch",
      url: `${BACKEND_URL}/UpdateCollectionImage/${id}`,
      data: Obj,
      headers: {
        token: localStorage.getItem("Token"),
      },
    };
    axios(config)
      .then((res) => {
        handleClose();
        getAllCollectionImage();
        clearState();
      })
      .catch((res) => {});
  }

  function updateShipment(Obj, handleClose, clearState, id) {
    setAddSizeLoading(true);
    const config = {
      method: "post",
      url: `${BACKEND_URL}/UpdateShipment/${id}`,
      data: Obj,
      headers: {
        token: localStorage.getItem("Token"),
      },
    };
    axios(config)
      .then((res) => {
        handleClose();
        getAllShipment();
        clearState();
        setAddSizeLoading(false);
      })
      .catch((res) => {
        setAddSizeLoading(false);
      });
  }

  function deleteSize(id) {
    setAddSizeLoading(true);
    const config = {
      method: "post",
      url: `${BACKEND_URL}/Deletesize/${id}`,
      headers: {
        token: localStorage.getItem("Token"),
      },
    };
    axios(config)
      .then((res) => {
        getSize();
      })
      .catch((res) => {});
  }
  function deleteShipment(id) {
    setAddSizeLoading(true);
    const config = {
      method: "post",
      url: `${BACKEND_URL}/DeleteShipment/${id}`,
      headers: {
        token: localStorage.getItem("Token"),
      },
    };
    axios(config)
      .then((res) => {
        getAllShipment();
      })
      .catch((res) => {});
  }

  function deleteCollection(id) {
    const config = {
      method: "delete",
      url: `${BACKEND_URL}/DeleteCollectionImage/${id}`,
      headers: {
        token: localStorage.getItem("Token"),
      },
    };
    axios(config)
      .then((res) => {
        getAllCollectionImage();
      })
      .catch((res) => {});
  }

  useEffect(() => {
    getAllAttachment();
  }, [page, dateFilter]);

  useEffect(() => {
    getAllShipment();
    getAllCollectionImage();
  }, []);
  const [isConfirmed, setConfirmed] = useState(false);

  return (
    <AppContext.Provider
      value={{
        deleteCollection,
        setAttachment,
        updateCollection,
        dateFilter,
        setDateFilter,
        createCollection,
        setPage,
        setLoading,
        page,
        loading,
        attachment,
        collectionData,
        isConfirmed,
        setConfirmed,
        updateShipment,
        createShipment,
        deleteShipment,
        shipmentData,
        getAllShipment,
        GetAllConfirmSales,
        filteredSales1Days,
        filteredSales30Days,
        filteredSales7Days,
        filteredSales365Days,
        token,
        AllCategory,
        GetAllCategories,
        GetAllMultipleProducts,
        AllMultipleProducts,
        GetAllProducts,
        GetLogin,
        GetToken,
        TagsData,
        addTagLoading,
        AllProducts,
        refresh,
        setRefresh,
        createColor,
        getColor,
        addColorLoading,
        colorLoading,
        colorData,
        addTagsLoading,
        getTags,
        updateColor,
        deleteColor,
        sizeData,
        addSizeLoading,
        sizeLoading,
        getSize,
        updateSize,
        deleteSize,
        createSize,
        GetAllRatings,
        AllRatings,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
