import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import Header from "../../components/Headers/Header.js";
import componentStyles from "../../assets/theme/views/admin/tables.js";
import { withAppContext } from "../../contextApi/Auth/index.js";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import CreateCollection from "./CreateCollection.js";
import { Avatar } from "antd";
import { BACKEND_URL } from "../../env.js";

const useStyles = makeStyles(componentStyles);

const CollectionsImage = ({
  getSize,
  deleteCollection,
  collectionData,
  ...props
}) => {
  const classes = useStyles();

  const [openModel, setOpenModel] = useState(false);

  const [UpdateData, setUpdateData] = useState("");

  const handleClickOpen = () => {
    setOpenModel(true);
  };

  const handleClose = () => {
    setUpdateData("");
    setOpenModel(false);
  };

  return (
    <>
      <Header />
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <Card classes={{ root: classes.cardRoot }}>
          <CardHeader
            className={classes.cardHeader}
            title="Available Collections"
            titleTypographyProps={{
              component: Box,
              marginBottom: "0!important",
              variant: "h3",
            }}
            action={
              <Button variant="outlined" onClick={handleClickOpen}>
                Create Collection
              </Button>
            }
          ></CardHeader>

          <TableContainer>
            <Box
              component={Table}
              alignItems="center"
              marginBottom="0!important"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    classes={{
                      root:
                        classes.tableCellRoot + " " + classes.tableCellRootHead,
                    }}
                  >
                    ID
                  </TableCell>
                  <TableCell
                    classes={{
                      root:
                        classes.tableCellRoot + " " + classes.tableCellRootHead,
                    }}
                  >
                    File
                  </TableCell>
                  <TableCell
                    classes={{
                      root:
                        classes.tableCellRoot + " " + classes.tableCellRootHead,
                    }}
                  >
                    Name
                  </TableCell>

                  <TableCell
                    classes={{
                      root:
                        classes.tableCellRoot + " " + classes.tableCellRootHead,
                    }}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {collectionData?.map((a, i) => {
                  return (
                    <TableRow key={a?.id}>
                      <TableCell
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.tableCellRootBodyHead,
                        }}
                        component="th"
                        variant="head"
                        scope="row"
                      >
                        <Box alignItems="center" display="flex">
                          <Box display="flex" alignItems="flex-start">
                            <Box fontSize=".875rem" component="span">
                              {i + 1}
                            </Box>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell classes={{ root: classes.tableCellRoot }}>
                        <Box paddingTop=".35rem" paddingBottom=".35rem">
                          <img
                            src={`${BACKEND_URL}/GetImage/${a.file}`}
                            alt={a.file}
                            style={{
                              width: 150,
                              height: 140,
                              objectFit: "contain",
                            }}
                          />
                        </Box>
                      </TableCell>
                      <TableCell classes={{ root: classes.tableCellRoot }}>
                        <Box paddingTop=".35rem" paddingBottom=".35rem">
                          {a?.name}
                        </Box>
                      </TableCell>

                      <TableCell classes={{ root: classes.tableCellRoot }}>
                        <Box display="flex" alignItems="center">
                          <Box
                            component="span"
                            marginRight=".5rem"
                            onClick={() => {
                              setOpenModel(true);
                              setUpdateData(a);
                            }}
                          >
                            <Edit style={{ width: "20px", height: "20px" }} />
                          </Box>
                          <Box
                            component="span"
                            marginRight=".5rem"
                            onClick={() =>
                              Swal.fire({
                                title: "Are you sure?",
                                text: "You won't be able to revert this!",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "Yes, delete it!",
                                preConfirm: () => {
                                  deleteCollection(a.id);
                                },
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  Swal.fire(
                                    "Deleted!",
                                    "Your Collection has been deleted.",
                                    "success"
                                  );
                                }
                              })
                            }
                          >
                            <Delete
                              style={{
                                color: "red",
                                width: "20px",
                                height: "20px",
                              }}
                            />
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Box>
          </TableContainer>
        </Card>
        <CreateCollection
          collectionData={collectionData}
          open={openModel}
          handleClose={handleClose}
          UpdateData={UpdateData}
          setUpdateData={setUpdateData}
        />
      </Container>
    </>
  );
};

export default withAppContext(CollectionsImage);
