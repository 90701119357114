import React from "react";
import "./fullViewLoader.css";
import Logo from "./Confirm Logo.png";
export default function FullViewLoader() {
  return (
    <div className="box">
      <img src={Logo} className="IMG" />
      <span className="loader-37"></span>
    </div>
  );
}
