// @material-ui/icons components
import FormatListBulleted from "@material-ui/icons/FormatListBulleted";
// core components
import Products from "./views/admin/Products";
import RatingTable from "./views/admin/Rating";
import Login from "./views/auth/Login";
import CategoryTable from "./views/admin/Category";
import SubCategory from "./views/admin/SubCategory";
// import AddProduct from "./views/admin/CreateProduct";
import Customer from "./views/admin/Customer";
import Guest from "./views/admin/Guest";
import Purchase from "./views/admin/Purchase";
import Sale from "./views/admin/Sale";
import CreatePurchase from "./views/admin/CreatePurchase";
import Banner from "./views/admin/Banner";
import Color from "./views/admin/Color";
import Size from "./views/admin/Size";
import DetailSale from "./views/admin/DetailSale";
import CreateProduct from "./views/admin/createProduct/CreateProduct";
import SendMailToCustomers from "./views/admin/sendMailToCustomers";
import Shipment from "./views/admin/Shipment";
import CollectionsImage from "./views/admin/CollectionsImage";
const routes = [
  {
    path: "/index",
    name: "Products",
    icon: FormatListBulleted,
    iconColor: "Error",
    component: Products,
    layout: "/admin",
  },
  {
    path: "/Category",
    name: "Category",
    icon: FormatListBulleted,
    iconColor: "Error",
    component: CategoryTable,
    layout: "/admin",
  },
  {
    path: "/SubCategory",
    name: "SubCategory",
    icon: FormatListBulleted,
    iconColor: "Error",
    component: SubCategory,
    layout: "/admin",
  },
  {
    path: "/Customer",
    name: "Customer",
    icon: FormatListBulleted,
    iconColor: "Error",
    component: Customer,
    layout: "/admin",
  },
  {
    path: "/Guest",
    name: "Guest",
    icon: FormatListBulleted,
    iconColor: "Error",
    component: Guest,
    layout: "/admin",
  },
  {
    path: "/rating",
    name: "Rating",
    icon: FormatListBulleted,
    iconColor: "Error",
    component: RatingTable,
    layout: "/admin",
  },
  {
    path: "/Purchase",
    name: "Purchase",
    icon: FormatListBulleted,
    iconColor: "Error",
    component: Purchase,
    layout: "/admin",
  },
  {
    path: "/Sale",
    name: "Sale",
    icon: FormatListBulleted,
    iconColor: "Error",
    component: Sale,
    layout: "/admin",
  },
  // {
  //   path: "/sendemail",
  //   name: "Send Email",
  //   icon: FormatListBulleted,
  //   iconColor: "Error",
  //   component: SendMailToCustomers,
  //   layout: "/admin",
  // },
  {
    path: "/Banner",
    name: "Banner",
    icon: FormatListBulleted,
    iconColor: "Error",
    component: Banner,
    layout: "/admin",
  },
  {
    path: "/AddColor",
    name: "Color",
    icon: FormatListBulleted,
    iconColor: "Error",
    component: Color,
    layout: "/admin",
  },

  {
    path: "/AddSize",
    name: "Size",
    icon: FormatListBulleted,
    iconColor: "Error",
    component: Size,
    layout: "/admin",
  },
  {
    path: "/shipment",
    name: "Shipment",
    icon: FormatListBulleted,
    iconColor: "Error",
    component: Shipment,
    layout: "/admin",
  },
  {
    path: "/collection",
    name: "Collection",
    icon: FormatListBulleted,
    iconColor: "Error",
    component: CollectionsImage,
    layout: "/admin",
  },
  {
    path: "/login",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/addProduct",
    component: CreateProduct,
    layout: "/admin",
  },
  {
    // 	path: "/DetailSale",
    // 	// name: "DetailSale",
    // 	// icon: FormatListBulleted,
    // 	// iconColor: "Error",
    // 	component: DetailSale,
    // 	layout: "/admin"
    path: "/DetailSale",
    component: DetailSale,
    layout: "/admin",
  },
  {
    path: "/CreatePurchase",
    component: CreatePurchase,
    layout: "/admin",
  },
];
export default routes;
